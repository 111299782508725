import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import { LanguageProvider } from './contexts/language';
import { AuthProvider } from './contexts/auth';
import Routing from './routes';

function App() {
    return (
        <LanguageProvider>
            <AuthProvider>
                <Routing />
            </AuthProvider>
        </LanguageProvider>
    );
}

export default App;