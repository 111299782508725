import React from 'react';

export default function Loader() {

    const lang = localStorage.getItem('language');

    return (
        <div className="loader-page">
            <div className="spinner-content"><div className="bt-spinner"></div></div>
            <div className="spinner-text">{lang==='pt' ? 'Carregando' : 'Loading'}...</div>
        </div>
    );
}